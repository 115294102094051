import axios, { AxiosError, AxiosInstance, AxiosResponse, GenericAbortSignal, InternalAxiosRequestConfig } from "axios";
import { setLoadingStatus } from "store/slices/loading-status-slice";
import { store } from "store";
import { getKeyFromUrl } from "helper/HelperFunctions";
import { history } from "route";
import AppRoute from "route";
import { setRestrictedAccessPath } from "store/slices/restricted-access-slice";
import appInsights from "ai-logger/TelemetryService";
import { setUserPrivilegeChangedModel } from "store/slices/user-previlege-changed-slice";
import { LogoutCause } from "common/enum";

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    "Content-Type": "application/json",
    credentials: "include"
  }
};
// Axios Interceptor to intercept all the API requests
export class AxiosService {
  axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = axios.create(defaultOptions);
    this.setUpInterceptors();
  }

  logException = (endpoint: string, params: any, method: any, message: string) => {
    let operationText = "";
    switch (method) {
      case 'get':
        operationText = "Fetching";
        break;
      case 'post':
        operationText = "Adding";
        break;
      case 'put':
        operationText = "Updating";
        break;
      case 'delete':
        operationText = "Deleting";
        break;
      default:
        operationText = "API Request"
    }
    let errorText = params ? `failed for the request having parameters ${params} with Error` : 'failed with Error';
    let exception = { exception: new Error(`${endpoint}: ${operationText} ${errorText} ${message}`) };
    appInsights.trackException(exception);
  }


  setUpInterceptors() {
    // Request interceptor
    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const key = getKeyFromUrl(config.url);
        store.dispatch(setLoadingStatus({ key: key, value: true }));
        const appState: any = store.getState();
        if (appState?.authReducer?.authentication?.user?.access_token) {
          if (config.headers) {
            config.headers.Authorization = `Bearer ${appState.authReducer.authentication.user.access_token}`;
            // you can update more header properties here
          }
        }
        return config;
      },
      (error: AxiosError) => {
        const key = getKeyFromUrl(error.config?.url);
        store.dispatch(setLoadingStatus({ key: key, value: false }));
        return Promise.reject(error);
      }
    );
    // Response interceptor
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        const key = getKeyFromUrl(response.request.responseURL);
        store.dispatch(setLoadingStatus({ key: key, value: false }));
        return response;
      },
      (error: AxiosError) => {
        const key = getKeyFromUrl(error.config?.url);
        store.dispatch(setLoadingStatus({ key: key, value: false }));
        const errorResponse: any = error?.response;
        const errCode = errorResponse?.status;
        const errors = errorResponse?.data?.errors;
        this.logException(key, error.config?.params, error.config?.method, errors?.gatherId[0] || error?.message)
        if (errCode) {
          switch (errCode) {
            case 400:
              // handle client-side error
              break;
            case 401:
              // handle client-side error
              break;
            case 404:
              // handle client-side error
              break;
            case 419:
              // handle client-side error
              store.dispatch(setUserPrivilegeChangedModel({ logoutCause: LogoutCause.UserPermissionChange, isUserPrivilegeChanged: true }));
              break;
            case 500:
              // handle server-side error
              break;
            case 504:
              // handle server-side error
              break;
            case 512:
              history.push(AppRoute.RESTRICTED_ACCESS);
              store.dispatch(setRestrictedAccessPath(AppRoute.RESTRICTED_ACCESS));
              return new Promise(() => {}); 
              break;
            default:
              if (window.location.href.includes("restricted-access")) {
                //
              }
              break;
          }
        } else {
          store.dispatch(setLoadingStatus({ key: key, value: false }));
          return Promise.reject(error);
        }
      }
    );
  }
  get = (url: string, headers?: any, isBlobResponseType?: boolean) => {
    return this.axiosInstance
      .get(url, {
        headers: { ...defaultOptions.headers, ...headers },
        responseType: isBlobResponseType ? "blob" : "json"
      })
      .then((response) => {
        return response;
      });
  };

  post = (url: string, data: any, headers?: any, isBlobResponseType?: boolean, withCredentials?: boolean) => {
    return this.axiosInstance
      .post(url, data, {
        headers: { ...defaultOptions.headers, ...headers },
        responseType: isBlobResponseType ? "blob" : "json",
        withCredentials
      })
      .then((response) => {
        return response;
      });
  };

  delete = (url: string, headers: any = {}) =>
    this.axiosInstance
      .delete(url, {
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));

  deleteWithPayload = (url: string, data?: any, headers?: any) =>
    this.axiosInstance
      .delete(url, {
        data,
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));

  put = (url: string, data?: any, headers?: any) =>
    this.axiosInstance
      .put(url, data, {
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));

  patch = (url: string, data?: any, headers?: any) => {
    return this.axiosInstance
      .patch(url, data, {
        headers: { ...defaultOptions.headers, ...headers }
      })
      .then((response) => (response?.data ? response.data : response));
  };
}

const Axios = new AxiosService();
export default Axios;